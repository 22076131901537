@import "fonts";
@import "variables";

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--bold);
  overflow-x: hidden;
  box-sizing: border-box;
  // background-color: #FAF7FD;
  background: #ffffff;


}

body.rtl {
  font-family: var(--regularAr) !important;
  overflow-x: hidden;
}

/***************** Common *************************/
.gradient-bg {
  background: var(--gradient);
}
button.gradient-bg:hover {
    background: var(--gradientHover);
}



.orangeGradient-bg {
  background: var(--orangeGradient);
}

button.orangeGradient-bg:hover {
    background: var(--orangeGradientHover);
}
button.contactSendBtn:hover, button.protBtn:hover {
    background: var(--orangeGradientHover) !important;
}




.lightBlue_gradient {
  background: var(--lightBlueGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.lightOrange_gradient {
  background: var(--lightOrangeGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.blueColor {
  background: var(--blueGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.orangeColor {
  background: var(--orangeGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.greenColor {
  background: var(--greenGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.header-btn {
  color: #fff;
}

.common-space {
  padding-top: 55px;
  padding-bottom: 55px;
}

.social-link {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -105px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  height: 150px;
  flex-wrap: wrap;

  &>div {
    position: relative;
    right: 120px;
    border-radius: 11px;
    width: 100%;
    padding: 8px;
    background: var(--gradient);
    transition: all 0.4s ease-in-out;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      /* background: linear-gradient(white, white) padding-box,
        linear-gradient(219.06deg, #b959e3 6.88%, #662782 77.05%) border-box; */
      pointer-events: none;
      z-index: -1;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='gradient' x1='1' y1='0' x2='0' y2='0'%3e%3cstop offset='6.88%25' stop-color='%23b959e3' /%3e%3cstop offset='77.05%25' stop-color='%23662782' /%3e%3c/linearGradient%3e%3c/defs%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='50' stroke='url(%23gradient)' stroke-width='2' stroke-dasharray='3%2c 5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");

      border-radius: 14px;
    }

    &:hover {
      right: 130px;
    }
    a{
      width: 17px;
      height: 17px;
      float: left;
    }
  }

  & svg {
    cursor: pointer;
    width: 17px;
    height: 17px;
    float: left;
  }
}
.BannerImgAr, .BannerImg{
  display: block;
}
.BannerImgArRes, .BannerImgRes{
  display: none;
}
.spinner {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid;
  border-color: var(--darkColor) transparent;
  animation: spin 1s infinite ease-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

iframe {
  width: 100%;
  height: 450px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.logo {

  &>span {
    width: 220px;

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.about2-content::before {
  content: '';
  position: absolute;
  bottom: -70px;
  left: 170px;
  width: 31%;
  height: 65%;
  background-image: url(../images/png/baby-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;


}

.feature-container::after {
  position: absolute;
  width: 240px !important;
  height: 250px !important;
}



/***************** RTL DIrection*************************/

html[dir='rtl'] {
  position: relative !important;
  width: 100%;
  overflow-x: hidden;
}

body .rtl {
  position: relative !important;
  font-family: var(--arRegular);
  font-style: normal;
  font-size: 15px;
}

.rtl .about-2-img,
.rtl .about-2-img img,
.rtl .cntct-btn svg,
.rtl .about2-content::before {
  transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
  -o-transform: scaleX(-1) !important;
}

.rtl .about2-content::before {
  left: 0 !important;
  right: 200px !important;
}

.rtl .banner-img {
  left: auto !important;
  right: -230px !important;
}

.rtl .banner-heading {
  text-align: right !important;
  transform: rotate(2.75deg) !important;
  line-height: 1.2 !important;
}
.rtl .products-head, .rtl .supplier-container .supplier-head, .rtl .contact-heading {
  line-height: 1.3 !important;
}
.rtl .banner-text {
  text-align: right !important;
  transform: rotate(3.62deg);
  line-height: 1.2 !important;
  font-family: var(--arBold) !important;
  padding-left: 0 !important;
  padding-right: 35px;
}

.rtl .banner-text2 {
  text-align: right !important;
  font-family: var(--arBold) !important;
  transform: rotate(0deg);
  padding-left: 0 !important;
  padding-right: 55px !important;
}

.rtl .about-text-content {
  width: 40%;
  padding-left: 0 !important;
  padding-right: 130px !important;
}

.rtl .about-2-img {
  left: 10px !important;
}

.rtl .about-text {
  text-align: right !important;

  &>div {
    line-height: 1.2;
  }
}

.rtl .about-contact-btn {
  justify-content: right !important;
}

.rtl .header-btn span,
.rtl .cntct-btn span {
  direction: ltr;
}

.rtl .features-heading {
  line-height: 1.2 !important;
}
.rtl .about-andHeading {
  left: unset !important;
  right: 63px !important;
  top: 68px !important;
}
.rtl .product-container .product-items > div{
  font-size: 20px !important;
}