@media (max-width: 1500px) {
  .batch{
    top: -30px !important;
    left: 80px !important;
  }
}
@media (max-width: 1399px) {
  .logo>span {
    width: 180px;
  }

  .about-2-img>span {
    width: 470px !important;
    bottom: -175px !important;
  }

  .about2-content {

    padding: 118px 0 !important;
  }
  .batch{
    left: 60px !important;
  }

}

@media (max-width: 1199px) {
  .logo>span {
    width: 120px;
  }

  .banner-img span img {
    max-width: 550px !important;
  }

  .banner-img {
    bottom: -190px !important;
    left: -70px !important;
  }

  .about-text-content {
    width: 100%;
    padding-left: 30px;
  }

  .about2-content::before {
    height: 50% !important;
  }

  .about-2-img>span {
    width: 440px !important;
  }

  // .about-content {
  //   height: 500px !important;
  //   background-position: center;
  // }

  .about-text {
    width: 60% !important;
    // padding-left: 110px !important;
  }

  // .about2-content {
  //   height: 450px !important;
  // }

  .product-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 50px !important;
  }

  .banner-img {
    bottom: -170px !important;
    left: -150px !important;
  }
  .batch{
    top: -50px !important;
    left: 0px !important;
  }
  .about-andHeading{
    left: 65px !important;
    top: 83px !important;
  }
}


@media (max-width: 991px) {
  .nav-bar-list {
    display: none !important;
    z-index: 110;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--black);
  }

  .showToggle {
    position: absolute !important;
    top: 70px;
    left: 0;
    background: var(--lightGradient) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    // color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #0ea3594f;
  }

  .showToggle div {
    border-bottom: 1px solid var(--white);
    outline: none;
    width: 100%;
    // padding: 20px 10px;
  }

  .showToggle.nav-bar-list a {
    background: none;
    padding: 10px 15px !important;
    display: flex;
    width: 100%;
  }

  .nav-bar-list a:hover::before,
  .nav-bar-list a.active::before {
    // background: transparent !important;
  }

  .showToggle a:hover {
    background: var(--themeColor) !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
  }

  .showToggle .active {
    background: var(--themeColor) !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
  }

  .banner-img span img {
    max-width: 500px !important;
  }

  .banner-img {
    bottom: -150px !important;
    left: -140px !important;
  }

  .banner-img img {
    width: 500px !important;
  }

  .banner-heading {
    font-size: 40px !important;
  }

  .banner-text {
    font-size: 30px !important;
  }

  .banner-text2 {
    font-size: 25px !important;
  }

  #about {
    margin-top: 0 !important;
  }

  .about-rows::before,
  .about2-content::before {
    display: none !important;
  }

  .about-2-img {
    display: none;
  }

  .about-text-content {
    width: 80% !important;
    /* padding-left: 80px !important; */
    display: flex;
    align-items: center;
  }

  .about-contact-btn {
    width: 50%;
  }

  .about-text {
    width: 100% !important;
  }

  .about-content {
    height: 380px !important;
  }

  .about-rows {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
  }

  .about2-content {
    // height: 100% !important;
    // background-size: cover !important;
    align-items: center !important;
  }

  .about-titleHeading {
    font-size: 23px !important;
  }

  .about-mainHeading {
    font-size: 26px !important;
  }

  .about-subheading {
    font-size: 30px !important;
  }

  .about-textHading {
    font-size: 17px !important;
  }

  .about2-content {
    height: 300px !important;
  }

  .feature-container {
    height: 750px !important;
  }

  .features-head::before,
  .features-head::after {
    width: 120px !important;
    height: 180px !important;
  }

  .feature-container::before,
  .feature-container::after {
    width: 150px !important;
    height: 140px !important;
    background-size: contain !important;
  }

  .about-rows .orangeColor {
    font-size: 30px !important;
  }

  .about-rows span {
    font-size: 22px !important;
  }

  .heading-blue,
  .lightBlue_gradient {
    font-size: 24px !important;
  }

  .heading-orange,
  .lightOrange_gradient {
    font-size: 36px !important;
  }

  .footerSocial,
  .f-logo-wrap {
    justify-content: center !important;
    text-align: center !important;
  }

  .footer-row {
    gap: 10px;
  }

  .batch {
    display: none !important;
  }

  .product-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .supply-grid {
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 30px !important;
  }


  .common-space {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .about2-content {
    background-size: contain !important;
  }
  .about-andHeading {
    left: 85px !important;
    top: 33px !important;
    font-size: 13px !important;
  }
  .tenDlr{
    font-size: 30px !important;
    margin-bottom: 30px;
  }

}

@media (max-width: 768px) {


  #home {
    height: auto !important;
    min-height: 500px !important;
  }

  .banner-img span img {
    max-width: 350px !important;
  }

  .banner-img {
    bottom: -130px !important;
    left: 120px !important;
  }

  .about2-content::before {
    display: none !important;
  }

  .about2-content {
    height: auto !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding: 53px 0;
  }

  .about-titleHeading {
    font-size: 24px !important;
  }

  .about-mainHeading {
    font-size: 23px !important;
  }

  .about-subheading {
    font-size: 25px !important;
  }

  .about-textHading {
    font-size: 14px !important;
  }

  #features {

    margin-top: 50px;
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .about-text-content {
    width: 100%;
    padding-left: 0 !important;
  }

  .down-btn {
    display: none !important;
  }
  .about-andHeading {
    top: 29px !important;
  }

}

@media (max-width: 767px){
  .down-btn{
    display: none;
  }
}

@media (max-width: 576px) {
  .banner-img span img {
    max-width: 300px !important;
  }

  .about-contact-btn {
    width: 70%;
  }

  .feature-container::before,
  .feature-container::after {
    width: 133px !important;
    height: 130px !important;
  }

  .features-head::before,
  .features-head::after {
    // width: 120px !important;
    // height: 150px !important;
    display: none !important;
  }

  .feature-container {
    width: 100%;
    height: 640px !important;
  }

  .social-link,
  .header-btn,
  .footer-list {
    display: none !important;
  }

  .feature-text {
    width: 100% !important;
  }

  .product-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .supply-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 30px !important;
  }

  .banner-content-wrapper {
    justify-content: center !important;
    align-items: center !important;
    position: relative;
    bottom: 40px;

  }

  .banner-img {
    bottom: -110px !important;
    left: 90px !important;
  }

  .contact-Us {
    order: 1;
  }

  .contect-with-us {

    & div {
      justify-content: center !important;
    }
  }

  .about-content {
    height: 600px !important;
  }
  .BannerImgAr, .BannerImg{
    display: none;
  }
  .BannerImgArRes, .BannerImgRes{
    display: block;
  }
  .feature-name-one{
    padding-left: 10px;
    padding-right: 10px;
  }
}



@media (max-width: 480px) {


  #home {
    height: auto !important;
    min-height: 450px !important;
  }

  .about-rows .orangeColor {
    font-size: 26px !important;
  }

  .about-rows span {
    font-size: 20px !important;
  }

  .banner-heading {
    font-size: 28px !important;
  }

  .banner-text {
    font-size: 25px !important;
  }

  .banner-text2 {
    font-size: 20px !important;
  }

  .banner-img {
    bottom: -130px !important;
    left: 40px !important;
  }

  .about-text-content {
    width: 100% !important;
    padding: 10px 0 !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .about-text {
    text-align: center !important;
  }

  .about-contact-btn {
    justify-content: center !important;
    width: 100%;
  }

  .heading-blue,
  .lightBlue_gradient {
    font-size: 24px !important;
  }

  .heading-orange,
  .lightOrange_gradient {
    font-size: 28px !important;
  }

  .feature-container::before,
  .feature-container::after {
    width: 93px !important;
    height: 90px !important;
  }

  .feature-container::before {
    left: 0 !important;
  }

  .product-main {
    justify-content: center !important;
  }

  .features-head::before,
  .features-head::after {
    display: none !important;
  }

  .features-head::before {
    left: -30px !important;
  }

  button {
    font-size: 13px !important;
  }

  p,
  .feature-text {
    font-size: 14px !important;
    text-align: justify !important;
  }

  .feature-container::before,
  .feature-container::after {
    bottom: 100px !important;
  }

  .diaper-img {
    max-width: 250px !important;
  }
  .about-andHeading {
    left: 46% !important;
    top: 33px !important;
  }
  .feature5 {
    top: 102px !important;
    right: -14px !important;
  }
  .feature3 {
    top: 102px !important;
    left: -12px !important;
  }
  .feature2 {
    right: 25.3% !important;
    bottom: 271px !important;
  }
  .about-contact-btn{

  }
  .about-contact-btn > div, .act-prod > div{
    max-width: 100% !important;
    justify-content: center;
    display: flex;
  }
}

@media (max-width: 400px) {
  .banner-img span img {
    max-width: 260px !important;
  }

  .banner-img {
    bottom: -130px !important;
    left: 0px !important;
  }

  .feature-name {
    font-size: 10px !important;
  }

  .eUHgeD .feature2 {
    right: 27% !important;
    bottom: 285px !important;
  }
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {

  .rtl .banner-img {
    left: auto !important;
    right: -240px !important;
  }

  .rtl .about-2-img>span {
    left: 25px !important;
    width: 445px !important;
    bottom: -155px !important;
  }

  .rtl .about2-content {
    padding: 72px 0 !important;
  }
  .rtl .about-text {
    padding-top: 45px;
  }
  .rtl .about-text-content {
    padding-right: 160px !important;
  }
  .rtl .about-andHeading {
    top: 113px !important;
  }
}

@media (max-width: 1199px) {

  .rtl .banner-img {
    right: -170px !important;
  }
  .rtl .about-text-content {
    padding-right: 150px !important;
  }
}

@media (max-width: 991px) {
  .rtl .about-andHeading {
    right: 38px !important;
    top: 90px !important;
  }
}
@media (max-width: 768px) {

  .rtl .banner-img {
    left: auto !important;
    right: 130px !important;
  }

  .rtl .about-text-content {
    width: 40%;
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
}

@media (max-width: 576px) {

  .rtl .about-text {
    text-align: center !important;
  }

  .rtl .about-contact-btn {
    justify-content: center !important;
  }

  .rtl .banner-img {
    left: auto !important;
    right: 100px !important;
  }
}

@media (max-width: 480px) {

  .rtl .banner-img {
    left: auto !important;
    right: 70px !important;
  }
  .rtl .about-andHeading {
    right: 46% !important;
    top: 90px !important;
  }
}

@media (max-width: 400px) {

  .rtl .banner-img {
    left: auto !important;
    right: 10px !important;
  }
}