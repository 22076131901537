:root {
    --themeColor: #5A2B93;
    // --silverColor: linear-gradient(140deg, rgba(255,255,255,1) 20%, rgba(153,153,153,.7) 100%);
    --gradient: linear-gradient(to right, #662782 40%,  #B959E3 100%);
    --lightGradient: linear-gradient(180deg, #DAF4FF 0%, #ECE3F7 100%);
    --blueGradient: linear-gradient(180deg, #1AA9EB 0%, #1278A8 100%);
    --lightBlueGradient: linear-gradient(to right, #fff 0%, #D6F2FF 100%);
    --lightOrangeGradient: linear-gradient(180deg, #fff 0%, #FFE7CF 100%);
    --orangeGradient: linear-gradient(to left, #FFAA06 0%, #D85A1F 100%);
    --greenGradient: linear-gradient(180deg, #28AE57 0%, #115B2B 100%);



    --gradientHover: linear-gradient(to right, #B959E3 100%, #662782 40%);
    --lightGradientHover: linear-gradient(180deg, #ECE3F7 100%, #DAF4FF 0%);
    --blueGradientHover: linear-gradient(180deg, #1278A8 100%, #1AA9EB 0%);
    --lightBlueGradientHover: linear-gradient(to right, #D6F2FF 100%, #fff 0%);
    --lightOrangeGradientHover: linear-gradient(180deg, #FFE7CF 100%, #fff 0%);
    --orangeGradientHover: linear-gradient(to left, #D85A1F 100%, #FFAA06 0%);
    --greenGradientHover: linear-gradient(180deg, #115B2B 100%, #28AE57 0%);



    --darkColor: #662782;
    --txtColor: #C866F4;
    --white: #FFFFFF;
    --black: #000;
    --gray: #F6F7FF;
    --text: #5F6B7A;
    --orange: #E66021;
    --textPalceholder: #5C718D;
    --whiteGradient: linear-gradient(to top, #ffffff 5%, #ECE3F7 100%);
    --whiteGradient2: linear-gradient(to top, #FFFFFF 5%, #F5EEFD 28%, #F5EEFD 68%, #FFFFFF 100%);
    --featureGradient: linear-gradient(to bottom, #ffffff 0%, #f6eef5 10%, #f6eef5 50%, #ffffff 100%);

    // --bg: #F3F6FA;
}