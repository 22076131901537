/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Mikado Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Regular"),
    url("../fonts/MikadoRegular.woff") format("woff");
}

@font-face {
  font-family: "Mikado Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Regular Italic"),
    url("../fonts/MikadoRegularItalic.woff") format("woff");
}

@font-face {
  font-family: "Mikado Light";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Light"), url("../fonts/MikadoLight.woff") format("woff");
}

@font-face {
  font-family: "Mikado Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Light Italic"),
    url("../fonts/MikadoLightItalic.woff") format("woff");
}

@font-face {
  font-family: "Mikado Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Medium"), url("../fonts/MikadoMedium.woff") format("woff");
}

@font-face {
  font-family: "Mikado Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Medium Italic"),
    url("../fonts/MikadoMediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Mikado Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Bold"), url("../fonts/MikadoBold.woff") format("woff");
}

@font-face {
  font-family: "Mikado Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Bold Italic"),
    url("../fonts/MikadoBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Mikado Black";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Black"), url("../fonts/MikadoBlack.woff") format("woff");
}

@font-face {
  font-family: "Mikado Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Black Italic"),
    url("../fonts/MikadoBlackItalic.woff") format("woff");
}

@font-face {
  font-family: "Mikado Ultra";
  font-style: normal;
  font-weight: bolder;
  src: local("Mikado Ultra"), url("../fonts/MikadoUltra.woff") format("woff");
}

@font-face {
  font-family: "Mikado Ultra Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Mikado Ultra Italic"),
    url("../fonts/MikadoUltraItalic.woff") format("woff");
}

@font-face {
  font-family: "Hacen Maghreb";
  font-style: normal;
  font-weight: normal;
  src: local("Hacen Maghreb"),
    url("../fonts/HacenMaghreb.ttf") format("truetype");
}

@font-face {
  font-family: "Hacen Maghreb Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Hacen Maghreb Regular"),
    url("../fonts/HacenMaghrebRegular.ttf") format("truetype");
}

/****************Arabic Fonts***************/

@font-face {
  font-family: "Noto Kufi Arabic Thin";
  src: url("../fonts/arabic/NotoKufiArabic-Thin.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraLight";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Light";
  src: url("../fonts/arabic/NotoKufiArabic-Light.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Regular";
  src: url("../fonts/arabic/NotoKufiArabic-Regular.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Medium";
  src: url("../fonts/arabic/NotoKufiArabic-Medium.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic SemiBold";
  src: url("../fonts/arabic/NotoKufiArabic-SemiBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Bold";
  src: url("../fonts/arabic/NotoKufiArabic-Bold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraBold";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Black";
  src: url("../fonts/arabic/NotoKufiArabic-Black.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --regular: "Mikado Regular";
  --regularAr: "Hacen Maghreb";
  --regularAr2: "Hacen Maghreb Regular";
  // --medium: "Mikado Light";
  --medium: "Mikado Medium";
  --bold: "Mikado Black";
  --bold2: "Mikado Bold";
  --extraBold: "Mikado Ultra";
  --logoEn: "Alex Brush";


  --arThin: "Noto Kufi Arabic Thin";
  --arExtraLight: "Noto Kufi Arabic ExtraLight";
  --arLight: "Noto Kufi Arabic Light";
  --arRegular: "Noto Kufi Arabic Regular";
  --arMedium: "Noto Kufi Arabic Medium";
  --arSemiBold: "Noto Kufi Arabic SemiBold";
  --arBold: "Noto Kufi Arabic Bold";
  --arExtraBold: "Noto Kufi Arabic ExtraBold";
  --arBlack: "Noto Kufi Arabic Black";
}